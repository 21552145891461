import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nicht gefunden" />
    <h1>Nicht gefunden</h1>
    <p>Diese Seite existiert leider nicht. Sollte sie existieren? Lassen Sie es mich bitte wissen!</p>
  </Layout>
);

export default NotFoundPage;
